import React from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetails.css';
import Navbar from './Navbar/Navbar';

const ProductDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const menuItems = [
    { id: 'overview', label: 'Overview', active: false },
    { id: 'product-details', label: 'Product Details', active: true },
    { id: 'components', label: 'Components', active: false },
    { id: 'apis', label: 'APIs', active: false },
    { id: 'database', label: 'Database', active: false },
    { id: 'analytics', label: 'Analytics', active: false },
    { id: 'settings', label: 'Settings', active: false },
    { id: 'deployment', label: 'Deployment', active: false },
  ];

  return (
    <div className="product-page">
      <Navbar />

      <div className="product-header">
        <div className="header-content">
          <h1>E-commerce Mobile App</h1>
          <div className="product-meta">
            Created 2 days ago • React Native • iOS & Android
          </div>
        </div>
        <div className="header-actions">
          <button className="share-button">Share</button>
          <button className="deploy-button">Deploy</button>
        </div>
      </div>

      <div className="product-content">
        <aside className="sidebar">
          <nav className="side-nav">
            {menuItems.map(item => (
              <a 
                key={item.id}
                href={`#${item.id}`}
                className={`nav-item ${item.active ? 'active' : ''}`}
              >
                {item.label}
              </a>
            ))}
          </nav>

          <div className="environment-section">
            <h2>ENVIRONMENT</h2>
            <button className="environment-button">
              Development
              <span className="chevron-down">▼</span>
            </button>
          </div>
        </aside>

        <main className="main-content">
          {/* Content area */}
        </main>
      </div>
    </div>
  );
};

export default ProductDetails; 