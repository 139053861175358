import React from 'react';
import './Login.css';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import builderLogo from '../assets/images/getbuilder-logo.png';
import { useAuth } from '../context/AuthContext';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleLoginSuccess = async (credentialResponse: any) => {
    try {
      // Decode the JWT token
      const payload = JSON.parse(atob(credentialResponse.credential.split('.')[1]));
      
      // Set user in context
      setUser({
        name: payload.name,
        email: payload.email,
        picture: payload.picture
      });

      navigate('/landing');
    } catch (error) {
      console.error('Error processing credentials:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="logo">
          <img src={builderLogo} alt="getbuilder.ai" className="logo-image" />
        </div>

        <div className="login-content">
          <h1>Sign in to getbuilder.ai</h1>
          <p className="subtitle">Start building your next great idea</p>
          
          <div className="signin-button-wrapper">
            <GoogleLogin
              onSuccess={handleLoginSuccess}
              onError={() => {
                console.log('Login Failed');
              }}
              useOneTap={false}
              type="standard"
              theme="outline"
              size="large"
              text="signin_with"
              shape="rectangular"
              width="100%"
            />
          </div>

          <div className="login-footer">
            <p className="terms">
              By signing in, you agree to our
              <a href="/terms">Terms</a> and{' '}
              <a href="/privacy">Privacy Policy</a>
            </p>

            <p className="signup-prompt">
              Don't have an account?
              <a href="/signup">Sign up for free</a>
            </p>
          </div>
        </div>

        <div className="feature-content">
          <h2>Build software faster with AI</h2>
          
          <div className="features">
            <div className="feature-item">
              <div className="feature-icon"></div>
              <div className="feature-text">
                <h3>Design with Natural Language</h3>
              </div>
            </div>

            <div className="feature-item">
              <div className="feature-icon"></div>
              <div className="feature-text">
                <h3>Generative AI Powered Development</h3>
              </div>
            </div>

            <div className="feature-item">
              <div className="feature-icon"></div>
              <div className="feature-text">
                <h3>Bring your expertise</h3>
              </div>
            </div>

            <div className="feature-item">
              <div className="feature-icon"></div>
              <div className="feature-text">
                <h3>Leverage domain specific builder expertize</h3>
              </div>
            </div>

            <div className="feature-item">
              <div className="feature-icon"></div>
              <div className="feature-text">
                <h3>Deploy with Confidence</h3>
              </div>
            </div>

            <div className="stats">
              <div className="stat-item">
                <h4>10x</h4>
                <p>Faster Development</p>
              </div>
              <div className="stat-item">
                <h4>1M+</h4>
                <p>Components Built</p>
              </div>
              <div className="stat-item">
                <h4>50k+</h4>
                <p>Happy Developers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login; 