import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.css';
import Navbar from './Navbar/Navbar';

interface Project {
  id: string;
  title: string;
  lastEdited: string;
  type: 'my-stuff' | 'websites' | 'mobile-apps' | 'ai-tools' | 'backend';
}

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('my-stuff');
  const [prompt, setPrompt] = useState('');

  const samplePrompts = [
    'A React dashboard app',
    'E-commerce mobile app',
    'AI chatbot website',
    'Social media backend',
    'Video streaming platform'
  ];

  const allProjects: Project[] = [
    // My Stuff
    { id: '1', title: 'Social Network Dashboard', lastEdited: '2 days ago', type: 'my-stuff' },
    { id: '2', title: 'E-commerce App', lastEdited: '5 days ago', type: 'my-stuff' },
    { id: '3', title: 'AI Chat Interface', lastEdited: '1 week ago', type: 'my-stuff' },
    { id: '4', title: 'Portfolio Website', lastEdited: '2 weeks ago', type: 'my-stuff' },
    { id: '5', title: 'Task Manager', lastEdited: '3 weeks ago', type: 'my-stuff' },
    { id: '6', title: 'Weather App', lastEdited: '1 month ago', type: 'my-stuff' },

    // Websites
    { id: '7', title: 'E-commerce Website', lastEdited: '1 day ago', type: 'websites' },
    { id: '8', title: 'Blog Platform', lastEdited: '3 days ago', type: 'websites' },
    { id: '9', title: 'Landing Page Builder', lastEdited: '1 week ago', type: 'websites' },
    { id: '10', title: 'Real Estate Portal', lastEdited: '2 weeks ago', type: 'websites' },
    { id: '11', title: 'Restaurant Website', lastEdited: '3 weeks ago', type: 'websites' },
    { id: '12', title: 'Portfolio Template', lastEdited: '1 month ago', type: 'websites' },

    // Mobile Apps
    { id: '13', title: 'Food Delivery App', lastEdited: '12 hours ago', type: 'mobile-apps' },
    { id: '14', title: 'Fitness Tracker', lastEdited: '2 days ago', type: 'mobile-apps' },
    { id: '15', title: 'Social Media App', lastEdited: '4 days ago', type: 'mobile-apps' },
    { id: '16', title: 'Travel Companion', lastEdited: '1 week ago', type: 'mobile-apps' },
    { id: '17', title: 'Music Player', lastEdited: '2 weeks ago', type: 'mobile-apps' },
    { id: '18', title: 'Todo List App', lastEdited: '1 month ago', type: 'mobile-apps' },

    // AI Tools
    { id: '19', title: 'Image Recognition API', lastEdited: '1 day ago', type: 'ai-tools' },
    { id: '20', title: 'Text Analyzer', lastEdited: '3 days ago', type: 'ai-tools' },
    { id: '21', title: 'Chatbot Builder', lastEdited: '1 week ago', type: 'ai-tools' },
    { id: '22', title: 'Voice Assistant', lastEdited: '2 weeks ago', type: 'ai-tools' },
    { id: '23', title: 'Recommendation Engine', lastEdited: '3 weeks ago', type: 'ai-tools' },
    { id: '24', title: 'ML Model Trainer', lastEdited: '1 month ago', type: 'ai-tools' },

    // Backend
    { id: '25', title: 'Authentication Service', lastEdited: '6 hours ago', type: 'backend' },
    { id: '26', title: 'Payment Gateway', lastEdited: '2 days ago', type: 'backend' },
    { id: '27', title: 'Database API', lastEdited: '5 days ago', type: 'backend' },
    { id: '28', title: 'Message Queue', lastEdited: '1 week ago', type: 'backend' },
    { id: '29', title: 'File Storage Service', lastEdited: '2 weeks ago', type: 'backend' },
    { id: '30', title: 'Search Engine API', lastEdited: '1 month ago', type: 'backend' },
  ];

  const tabs = [
    { id: 'my-stuff', label: 'My Stuff' },
    { id: 'websites', label: 'Websites' },
    { id: 'mobile-apps', label: 'Mobile Apps' },
    { id: 'ai-tools', label: 'AI Tools' },
    { id: 'backend', label: 'Backend' }
  ];

  const filteredProjects = allProjects.filter(project => project.type === activeTab);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  const handleProjectClick = (projectId: string) => {
    navigate(`/product/${projectId}`);
  };

  return (
    <div className="landing-container">
      <Navbar />

      <main className="main-content">
        <h1>Start building with AI</h1>
        <p className="subtitle">Describe your project and let AI handle the details</p>

        <div className="prompt-container">
          <input
            type="text"
            placeholder="I want to build..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="prompt-input"
          />
          <button className="start-button">Start</button>
        </div>

        <div className="sample-prompts">
          <h2>SAMPLE PROMPTS</h2>
          <div className="prompt-chips">
            {samplePrompts.map((samplePrompt, index) => (
              <button 
                key={index} 
                className="prompt-chip"
                onClick={() => setPrompt(samplePrompt)}
              >
                {samplePrompt}
              </button>
            ))}
          </div>
        </div>

        <div className="tabs">
          {tabs.map(tab => (
            <button
              key={tab.id}
              className={`tab ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        <div className="projects-grid">
          {filteredProjects.map((project) => (
            <div 
              key={project.id} 
              className="project-card"
              onClick={() => handleProjectClick(project.id)}
            >
              <div className="project-preview"></div>
              <h3>{project.title}</h3>
              <p>Last edited {project.lastEdited}</p>
            </div>
          ))}
          <div className="new-project-card">
            <div className="add-icon">+</div>
            <p>New Project</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Landing; 