import React from 'react';
import './App.css';
import Login from './components/Login';
import Landing from './components/Landing';
import ProductDetails from './components/ProductDetails';
import ProtectedRoute from './components/ProtectedRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Documentation from './components/Documentation/Documentation';
import Pricing from './components/Pricing/Pricing';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <div className="App">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route 
                path="/landing" 
                element={
                  <ProtectedRoute>
                    <Landing />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/product/:id" 
                element={
                  <ProtectedRoute>
                    <ProductDetails />
                  </ProtectedRoute>
                } 
              />
              <Route path="/documentation" element={<Documentation />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/" element={<Navigate to="/login" replace />} />
            </Routes>
          </div>
        </GoogleOAuthProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
