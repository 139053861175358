import React, { useState } from 'react';
import './Pricing.css';
import Navbar from '../Navbar/Navbar';

const Pricing: React.FC = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const plans = [
    {
      name: 'Free',
      description: 'Perfect for personal projects',
      price: '0',
      buttonText: 'Get Started',
      buttonVariant: 'outline',
      features: [
        '3 projects',
        'Basic AI experts',
        'Community support',
        'Basic analytics'
      ],
      isPopular: false
    },
    {
      name: 'Pro',
      description: 'For teams and businesses',
      price: isAnnual ? '39' : '49',
      buttonText: 'Start Free Trial',
      buttonVariant: 'solid',
      features: [
        'Unlimited projects',
        'All AI experts',
        'Priority support',
        'Advanced analytics',
        'Custom deployment',
        'Team collaboration'
      ],
      isPopular: true
    },
    {
      name: 'Enterprise',
      description: 'Custom solutions for large teams',
      price: 'Custom pricing',
      buttonText: 'Contact Sales',
      buttonVariant: 'outline',
      features: [
        'Custom AI experts',
        'Dedicated support',
        'SLA guarantees',
        'Custom integrations',
        'On-premise deployment',
        'SSO & advanced security'
      ],
      isPopular: false
    }
  ];

  const faqs = [
    {
      question: "What's included in the free plan?",
      answer: "The free plan includes up to 3 projects, access to basic AI experts, community support, and basic analytics features."
    },
    // Add more FAQs as needed
  ];

  return (
    <div className="pricing-page">
      <Navbar />
      
      <div className="pricing-content">
        <h1>Simple, transparent pricing</h1>
        <p className="subtitle">Choose the perfect plan for your development needs</p>

        <div className="billing-toggle">
          <span className={!isAnnual ? 'active' : ''}>Monthly</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={isAnnual}
              onChange={() => setIsAnnual(!isAnnual)}
            />
            <span className="slider round"></span>
          </label>
          <span className={isAnnual ? 'active' : ''}>Annual (Save 20%)</span>
        </div>

        <div className="pricing-grid">
          {plans.map((plan) => (
            <div key={plan.name} className={`pricing-card ${plan.isPopular ? 'popular' : ''}`}>
              {plan.isPopular && <div className="popular-badge">POPULAR</div>}
              <h2>{plan.name}</h2>
              <p className="plan-description">{plan.description}</p>
              
              <div className="price">
                {plan.price === 'Custom pricing' ? (
                  <span className="custom-price">{plan.price}</span>
                ) : (
                  <>
                    <span className="currency">$</span>
                    <span className="amount">{plan.price}</span>
                    <span className="period">/month</span>
                  </>
                )}
              </div>

              <button className={`plan-button ${plan.buttonVariant}`}>
                {plan.buttonText}
              </button>

              <div className="features">
                <div className="features-label">
                  {plan.name === 'Free' ? 'Includes:' : `Everything in ${plan.name === 'Enterprise' ? 'Pro' : 'Free'}, plus:`}
                </div>
                <ul>
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="faq-section">
          <h2>Frequently asked questions</h2>
          <div className="faq-list">
            {faqs.map((faq, index) => (
              <details key={index} className="faq-item">
                <summary>{faq.question}</summary>
                <p>{faq.answer}</p>
              </details>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing; 