import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import builderLogo from '../../assets/images/getbuilder-logo.png';
import { useAuth } from '../../context/AuthContext';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogoClick = () => {
    navigate('/landing');
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav className="navbar">
      <div className="nav-left">
        <img 
          src={builderLogo} 
          alt="getbuilder.ai" 
          className="nav-logo"
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className="nav-right">
        <a href="/documentation">Documentation</a>
        <a href="/pricing">Pricing</a>
        <div className="user-profile" ref={dropdownRef}>
          {user ? (
            <>
              <div 
                className="profile-trigger"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <img src={user.picture} alt="" className="profile-picture" />
                <span className="user-name">{user.name}</span>
              </div>
              {isDropdownOpen && (
                <div className="profile-dropdown">
                  <div className="dropdown-header">
                    <img src={user.picture} alt="" className="dropdown-picture" />
                    <div className="dropdown-user-info">
                      <span className="dropdown-name">{user.name}</span>
                      <span className="dropdown-email">{user.email}</span>
                    </div>
                  </div>
                  <div className="dropdown-divider" />
                  <button className="dropdown-item" onClick={logout}>
                    Sign out
                  </button>
                </div>
              )}
            </>
          ) : (
            <button onClick={() => navigate('/login')}>Sign In</button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 