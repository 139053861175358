import React, { useState } from 'react';
import './Documentation.css';
import Navbar from '../Navbar/Navbar';
import { SearchIcon } from './icons';

const Documentation: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const mainNavItems = [
    { id: 'guides', label: 'Guides', active: true },
    { id: 'api-reference', label: 'API Reference', active: false },
    { id: 'examples', label: 'Examples', active: false },
    { id: 'tutorials', label: 'Tutorials', active: false },
    { id: 'component-library', label: 'Component Library', active: false },
  ];

  const sidebarItems = {
    'GETTING STARTED': [
      { id: 'introduction', label: 'Introduction', active: true },
      { id: 'quick-start', label: 'Quick Start', active: false },
      { id: 'installation', label: 'Installation', active: false },
    ],
    'CORE CONCEPTS': [
      { id: 'project-structure', label: 'Project Structure', active: false },
      { id: 'ai-experts', label: 'AI Experts', active: false },
      { id: 'components', label: 'Components', active: false },
      { id: 'prompts', label: 'Prompts', active: false },
    ],
    'ADVANCED TOPICS': [
      { id: 'custom-experts', label: 'Custom Experts', active: false },
      { id: 'api-integration', label: 'API Integration', active: false },
      { id: 'deployment', label: 'Deployment', active: false },
      { id: 'security', label: 'Security', active: false },
    ],
  };

  const tableOfContents = [
    { id: 'what-is', label: 'What is getbuilder.ai?' },
    { id: 'key-features', label: 'Key Features' },
    { id: 'getting-started', label: 'Getting Started' },
    { id: 'prerequisites', label: 'Prerequisites' },
    { id: 'next-steps', label: 'Next Steps' },
  ];

  return (
    <div className="documentation-page">
      <Navbar />
      
      <div className="doc-nav">
        <div className="main-nav">
          {mainNavItems.map(item => (
            <a 
              key={item.id}
              href={`#${item.id}`}
              className={`nav-item ${item.active ? 'active' : ''}`}
            >
              {item.label}
            </a>
          ))}
        </div>
        <div className="version-selector">
          v1.0.0
          <span className="chevron-down">▼</span>
        </div>
      </div>

      <div className="doc-content">
        <aside className="doc-sidebar">
          <div className="search-box">
            <SearchIcon />
            <input
              type="text"
              placeholder="Search documentation..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {Object.entries(sidebarItems).map(([category, items]) => (
            <div key={category} className="sidebar-section">
              <h3>{category}</h3>
              {items.map(item => (
                <a
                  key={item.id}
                  href={`#${item.id}`}
                  className={`sidebar-item ${item.active ? 'active' : ''}`}
                >
                  {item.label}
                </a>
              ))}
            </div>
          ))}
        </aside>

        <main className="main-content">
          <h1>Introduction</h1>
          <p className="subtitle">Learn how to use getbuilder.ai to create amazing applications</p>

          <div className="quick-links">
            <h2>Quick Links</h2>
            <div className="quick-links-grid">
              <a href="/quick-start">Quick Start →</a>
              <a href="/installation">Installation →</a>
              <a href="/first-project">First Project →</a>
            </div>
          </div>

          <section id="what-is">
            <h2>What is getbuilder.ai?</h2>
            <p>getbuilder.ai is an AI-powered platform for creating modern applications through natural</p>
            
            <div className="code-block">
              <div className="code-comment">// Example prompt</div>
              <div className="code-content">
                "Build a React dashboard with dark mode,
                authentication, and real-time data updates"
              </div>
            </div>
          </section>
        </main>

        <aside className="table-of-contents">
          <div className="toc-content">
            <h2>ON THIS PAGE</h2>
            {tableOfContents.map(item => (
              <a key={item.id} href={`#${item.id}`}>{item.label}</a>
            ))}
          </div>

          <div className="help-section">
            <h2>Need Help?</h2>
            <a href="https://discord.gg/getbuilder">Join our Discord</a>
            <a href="https://github.com/getbuilder/issues">GitHub Issues</a>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Documentation; 